import type { ItemModel, DataItemModel, RecordSourceOptions, RecordSourceFieldType } from 'o365.modules.DataObject.Types.ts';
import type { IRetrieveOptions } from 'o365.modules.DataObject.DataHandler.ts';

import type DataColumn from 'o365.controls.DataGrid.Column.ts';
import type DataGridControl from 'o365.controls.DataGrid.ts';


// @ts-ignore
import { TreeColumnHeaderFactory } from 'o365.vue.components.DataGrid.helpers.jsx';

import DataItem from 'o365.modules.DataObject.Item.ts';
import VersionedStorage from 'o365.modules.VersionedStorage.ts';
import { h } from 'vue';

import $t from 'o365.modules.translate.ts';
import logger from 'o365.modules.Logger.ts';
import utils from 'o365.modules.utils.js';
// import BulkOperation from 'o365.modules.utils.BulkOperation.ts';
import DataHandler from 'o365.modules.DataObject.DataHandler.ts';
import EventEmitter from 'o365.modules.EventEmitter.ts';
import { DataObject } from 'o365.modules.DataObject.ts';
import BulkOperation from 'o365.modules.utils.BulkOperation.ts'
declare module 'o365.modules.DataObject.ts' {
    interface DataObject<T> {
        nodeData: NodeData<T>;
        hasNodeData: boolean;
    }
}

Object.defineProperties(DataObject.prototype, {
    'nodeData': {
        get() {
            if (this._nodeData == null) {
                this._nodeData = new NodeData(this);
                this._nodeData.initialize();
            }
            return this._nodeData;
        }
    },
    'hasNodeData': {
        get() {
            return !!this._nodeData;
        }
    }
});

/**
 * Node Data (hierarchy / group by) extension for DataObject 
 */
export default class NodeData<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;
    private _initialized = false;
    private _levelConfigurations: INodeDataLevelConfiguration<T>[] = [];
    private _enabled = false;
    private _root: NodeItem<T>[] = [];
    private _data: DataItemModel<T>[] = [];
    private _updated: Date = new Date();
    private _localStorage: VersionedStorage<Record<string, true>, { id: string }>;
    /** Used to restore dynamic loading to previous state when disabling this extension */
    private _hasDynamicLoading?: boolean;

    /** When true will auto expand all nodes after filtering */
    autoExpandOnFilter = true;
    /**
     * When true will load all of the necessary fields for all of the rows with the current whereClause/filterString. The node
     * structure will then be constructed on client. This mode is not suited for very large datasets and is geared more towards <20k rows.
     */
    loadFullStructure = false;
    /** Current expanded level used by other controls */
    currentLevel = 0;
    /** Deepest level in the structure */
    deepestLevel = 0;
    /** Events emitter utilized by other controls for reacting to node data changes */
    events: EventEmitter<NodeDataEvents<T>>;
    /** When true setting isSelected will not update detail nodes isSelected values */
    disableDetailsMultiSelect = false;

    /** Original DataObject load */
    private _dataObject_load!: DataObject<T>['load'];
    /** Original RecordSource retrieve */
    private _recordSource_retrieve!: DataObject<T>['recordSource']['retrieve'];

    private _cancelAfterDelete?: () => void;
    private _cancelAfterSoftDelete?: () => void;
    private _cancelChangesCancelled?: () => void;
    private _cancelAfterSave?: () => void;

    canCreateNodes = false;

    /** Date at which display data was last updated. Used by watchers */
    get updated() { return this._updated; }
    /** Indicates if the node data overrides are currently enabled */
    get enabled() { return this._enabled; }

    get localStorageKey() {
        return `${this._dataObject.appId}_${this._dataObject.id}_nodeData`
    }

    /** Root array of NodeItems */
    get root() { return this._root; }

    /** Get the current index node item */
    get current() {
        if (this._dataObject.currentIndex == null) {
            return undefined;
        } else {
            return this._dataObject.storage.data[this._dataObject.currentIndex]?._getNode
                ? this._dataObject.storage.data[this._dataObject.currentIndex]._getNode()
                : undefined
        }
    }

    /** Node levels configurations */
    get configurations() { return this._levelConfigurations; }

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
        this._localStorage = new VersionedStorage({
            baseline: { id: `${this._dataObject.id}` },
            id: this.localStorageKey,
        });
        this.events = new EventEmitter();
    }

    /** Should not be called outside of DataObject */
    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;
        // Original DataObject functions
        this._dataObject_load = this._dataObject.load.bind(this._dataObject);
        this._recordSource_retrieve = this._dataObject.recordSource.retrieve.bind(this._dataObject.recordSource);
    }

    /** Enable NodeData overrides on the DataObject */
    enable() {
        if (this._enabled) { return; }

        this._enabled = true;
        this._data.splice(0, this._data.length);
        this._dataObject.setStoragePointer(this._data);

        this._dataObject.createNewAtTheEnd = true;

        this._dataObject.load = this.load.bind(this);
        this._dataObject.recordSource.retrieve = this.retrieve.bind(this);

        this._cancelAfterSave = this._dataObject.on('AfterSave', (_o, _i, pDataItem) => {
            if (pDataItem['_getNode']) {
                const node = pDataItem._getNode();
                const configuration = node.getConfiguration();
                node.canCreateNodes = configuration.canCreateNodes(node);
            }
        });

        this._cancelAfterDelete = this._dataObject.on('AfterDelete', this._handleAfterDelete.bind(this));
        this._cancelAfterSoftDelete = this._dataObject.on('AfterSoftDelete', this._handleAfterDelete.bind(this));

        this._cancelChangesCancelled = this._dataObject.on('ChangesCancelled', (rows) => {
            let updateDisplayArray = false;
            rows.forEach(row => {
                if (row.isNewRecord && row.isEmpty) {
                    // New record was canceled and removed from storage, remove from node data too.
                    const node: NodeItem<T> = (row as any)._getNode();
                    if (node) {
                        const parent = node.getParent();
                        if (parent) {
                            const index = parent.details.findIndex(x => x.key === node.key);
                            if (index === -1) { return; }
                            parent.details.splice(index, 1);
                            updateDisplayArray = true;
                        } else {
                            const index = this.root.findIndex(x => x.key === node.key);
                            if (index === -1) { return; }
                            this.root.splice(index, 1);
                            updateDisplayArray = true;
                        }
                    }
                }
            });

            if (updateDisplayArray) {
                this.update();
            }
        });
        if (this._dataObject.hasDynamicLoading) {
            this._hasDynamicLoading = this._dataObject.dynamicLoading.enabled;
            this._dataObject.dynamicLoading.enabled = false;
        }
    }

    /** Disable NodeData overrides on the DataObject */
    disable() {
        if (!this._enabled) { return; }

        this._enabled = false;
        this._dataObject.setStoragePointer(undefined);

        this._dataObject.load = this._dataObject_load;
        this._dataObject.recordSource.retrieve = this._recordSource_retrieve;

        if (this._cancelAfterDelete) {
            this._cancelAfterDelete();
            this._cancelAfterDelete = undefined;
        }
        if (this._cancelAfterSoftDelete) {
            this._cancelAfterSoftDelete();
            this._cancelAfterSoftDelete = undefined;
        }
        if (this._cancelChangesCancelled) {
            this._cancelChangesCancelled();
            this._cancelChangesCancelled = undefined;
        }
        if (this._cancelAfterSave) {
            this._cancelAfterSave();
            this._cancelAfterSave = undefined;
        }
        if (this._hasDynamicLoading != null) {
            this._dataObject.dynamicLoading.enabled = this._hasDynamicLoading;
        }
    }

    /** Push new level of structure configuration */
    addConfiguration(pOptions: NodeDataHierarchyConfigurationOptions<T> | NodeDataGroupByConfigurationOptions<T>, pLevel?: number) {
        if (pOptions.type == null) {
            (pOptions as any).type = 'hierarchy';
            logger.warn(`${this._dataObject.id}.nodeData.addConfiguration: Provided configuration has no type. Should be either 'hierarchy' or 'groupBy'`);
        }
        let config: INodeDataLevelConfiguration<T> | null = null
        if (pOptions.type === 'hierarchy') {
            config = new HierarchyLevelConfiguration(this._dataObject, pOptions, (pLevel) => {
                return this.configurations[pLevel];
            });
        } else {
            config = new GroupByLevelConfiguration(this._dataObject, pOptions, (pLevel) => {
                return this.configurations[pLevel];
            });
        }
        if (pLevel == null && this._levelConfigurations.at(-1)?.type === 'hierarchy') {
            pLevel = this._levelConfigurations.length - 1;
        }
        if (pLevel != null && pLevel < this.configurations.length) {
            this._levelConfigurations.splice(pLevel, 0, config!);
        } else {
            this._levelConfigurations.push(config!);
        }
        this.configurations.forEach((configuration, index) => configuration.level = index);
        this.events.emit('ConfigurationAdded', config);
    }

    /** Remove a configuration level */
    removeConfiguration(pLevel: number) {
        if (this._levelConfigurations.at(pLevel) != null) {
            this._levelConfigurations.splice(pLevel, 1);
        }
        this.configurations.forEach((configuration, index) => configuration.level = index);
    }

    /** Remove all configuration levels */
    removeAllConfigurations() {
        if (this._levelConfigurations.length > 0) {
            this._levelConfigurations.splice(0, this._levelConfigurations.length);
        }
    }

    /** Change configuration level */
    moveConfiguration(pFromLevel: number, pToLevel: number) {
        const config = this.configurations.splice(pFromLevel, 1)[0];
        this.configurations.splice(pToLevel, 0, config);
        this.configurations.forEach((configuration, index) => configuration.level = index);
    }

    /** TODO: Remove and encourage the usage of dataObject.load */
    async init() {
        if (this._levelConfigurations.length === 0) { return; }
        const config = this._levelConfigurations[0];
        const expandedKeys = this._localStorage.getStoredValue() ?? undefined;
        this._dataObject.state.isLoading = true;
        this._root = await config.getStructure({
            expandedKeys: expandedKeys,
            autoExpandOnFilter: this.autoExpandOnFilter
        });
        if (this._dataObject.recordSource.filterString) {
            this._root = await config.getStructure({
                expandedKeys: expandedKeys
            });
        }
        let setCurrentIndexPromise: Promise<any> | undefined = undefined;
        this._dataObject.state.isLoading = false;
        if (this._dataObject.selectFirstRowOnLoad) {
            if (this.root[0]?.isLoading) {
                setCurrentIndexPromise = this.root[0].loadingPromise?.then(() => {
                    const index = this.root[0].dataItem?.index;
                    if (index != null) {
                        this._dataObject.setCurrentIndex(index, true);
                    }
                });
            }
        }

        if (this._dataObject.allowInsert && this.root.length === 0) {
            const item = this._dataObject.storage.data.find(x => x.isNewRecord);
            const node = config.createNode(item ?? {});
            this.root.push(node);
        }

        this.updateRowCount();
        this.update();
        if (setCurrentIndexPromise) {
            await setCurrentIndexPromise;
        }
        this._dataObject.emit('DataLoaded', this._dataObject.data, {});
    }

    /** Expand all nodes that have details in the entire structure */
    expandAll() {
        const traverseExpand = (node: NodeItem<T>) => {
            if (node.hasNodes && !node.expanded) {
                node.expanded = true;
            }
            node.details.forEach(detail => traverseExpand(detail));
        };
        this._root.forEach(node => traverseExpand(node));
        this.update();
    }

    /** Expand all nodes that have details and have currently expanded parents */
    expandAllVisible() {
        const traverseExpand = (node: NodeItem<T>) => {
            if (node.hasNodes && !node.expanded) {
                node.expanded = true;
            } else {
                node.details.forEach(detail => traverseExpand(detail));
            }
        };
        this._root.forEach(node => traverseExpand(node));
        this.update();
    }

    /** Collapse all nodes that have details in the entire structure */
    collapseAll() {
        const traverseCollapse = (node: NodeItem<T>) => {
            if (node.expanded) {
                node.expanded = false;
            }
            node.details.forEach(detail => traverseCollapse(detail));
        };
        this._root.forEach(node => traverseCollapse(node));
        this.update();
    }

    /** Collapse all nodes that have details and have currently expanded parents */
    collapseAllVisible() {
        const traverseCollapse = (node: NodeItem<T>) => {
            if (node.expanded) {
                node.expanded = false;
            } else {
                node.details.forEach(detail => traverseCollapse(detail));
            }
        };
        this._root.forEach(node => traverseCollapse(node));
        this.update();
    }

    /** 
     * Expand/collapse rows to a given level
     */
    expandToLevel(pLevel: number) {
        if (pLevel > this.deepestLevel) { pLevel = this.deepestLevel; }
        if (pLevel < 0) { pLevel = 0; }
        const traverseExpand = (node: NodeItem<T>) => {
            if (node.hasNodes) {
                node.expanded = node.level < pLevel;
            }
            node.details.forEach(detail => traverseExpand(detail));
        };
        this._root.forEach(node => traverseExpand(node));
        this.currentLevel = pLevel;
        this.update();
    }

    /** Flatten out expanded nodes from root into an array */
    getFlatStructure() {
        const result: (DataItemModel<T> | undefined)[] = [];
        let index = 0;
        const pushDetails = (node: NodeItem<T>) => {
            node.displayIndex = index++;
            // @ts-ignore
            result.push(node);
            if (node.expanded) {
                node.details.forEach(detail => pushDetails(detail));
            }
        };
        this._root.forEach(node => pushDetails(node));
        return result;
    }

    /** Update the DataObject row count with the loaded structure items count */
    updateRowCount() {
        if (!this._enabled) { return; }
        let rowCount = 0;
        this._root.forEach(item => {
            rowCount += item.count + 1;
        });
        // let rowCount = 0;
        // const traverseCount = (node: NodeItem<T>, pLevel = 0) => {
        //     // TODO(Augustas): Exclude non DataItem nodes from count
        //     if (!node.isSummaryItem) {
        //         rowCount += 1;
        //     }
        //     node.level = pLevel;
        //     node.details.forEach(detail => traverseCount(detail, pLevel + 1));
        // };
        // this._root.forEach(node => traverseCount(node));
        this._dataObject.state.rowCount = rowCount;
    }

    addNode(pItem: DataItemModel<T>, pOptions?: {
        /** If provided will push to this node's details. When none is provided will push to root */
        parentNode?: NodeItem<T>,
        /** If provided will push right after this node. When none provided will push to the end of parent/root */
        siblingNode?: NodeItem<T>
    }) {
        if (!this._enabled) { return; }

        let config: INodeDataLevelConfiguration<T> | null = null;
        if (pOptions?.siblingNode) {
            config = pOptions.siblingNode.getConfiguration();
        } else if (pOptions?.parentNode) {
            config = pOptions.parentNode.getConfiguration();
        } else {
            config = this._levelConfigurations.at(0) || null;
        }
        if (config == null) {
            logger.warn('Could not get configuration for creating new node')
            return;
        }

        const node = config.createNode({
            item: pItem
        });
        if (pOptions?.siblingNode) {
            const parent = pOptions.siblingNode.getParent();
            if (parent) {
                const siblingIndex = parent.details.findIndex(x => x.key === pOptions.siblingNode!.key);
                if (siblingIndex == -1) {
                    logger.warn('Failed to append node due to malformed structure. Provided sibling node is not found in its parent node');
                    return;
                }
                node.level = parent.level + 1;
                parent.details.splice(siblingIndex + 1, 0, node);;
                node.getParent = () => parent;
            } else {
                const siblingIndex = this._root.findIndex(x => x.key === pOptions.siblingNode!.key);
                if (siblingIndex == -1) {
                    logger.warn('Failed to append node due to malformed structure. Provided sibling node is not found in root');
                    return;
                }
                node.level = 0;
                this._root.splice(siblingIndex + 1, 0, node);;
            }
        } else if (pOptions?.parentNode) {
            node.level = pOptions.parentNode.level + 1;
            pOptions.parentNode.details.push(node);
            node.getParent = () => pOptions.parentNode;
        } else {
            node.level = 0;
            this._root.push(node);
        }
        this.update();
        this.events.emit('NodeAdded', node);
        return node;
    }

    /** Update the displayed data with flattened node items */
    update() {
        this._data.splice(0, this._data.length, ...this.getFlatStructure() as DataItemModel<T>[]);
        this._saveExpandedState();
        this._updated = new Date();
    }

    /**
     * Get data required for constructing the node structure based on current configuration
     * Only used when loadFullStructure is enabled
     */
    async getData() {
        const fieldsToLoad = new Set<string>();
        this.configurations.forEach(configuration => {
            const fields = configuration.getRequiredFields();
            fields.forEach(field => fieldsToLoad.add(field));
        });
        const options = this._dataObject.recordSource.getOptions();
        options.skip = 0;
        options.maxRecords = -1;
        options.fields = Array.from(fieldsToLoad).map(field => ({ name: field }));
        this._dataObject.recordSource.appendSortByFields(options.fields); // TODO: Check typing here
        const data = await this._recordSource_retrieve(options);
        return data;
    }

    /**
     * Find NodeItem in the loaded structure with the provided PrimKey
     */
    findNodeByPrimKey(pPrimKey: string) {
        const traverseFind = (nodeArray: NodeItem<T>[]): NodeItem<T> | null => {
            for (const node of nodeArray) {
                if (node.primKey == pPrimKey) {
                    return node;
                }

                if (node.hasNodes) {
                    const foundNode: NodeItem<T> | null = traverseFind(node.details);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
            return null;
        };
        return traverseFind(this._dataObject.nodeData.root);
    }

    /**
    * Find NodeItem in the loaded structure with the provided PrimKey
    */
    findNodeByFetchKey(pKey: string) {
        const traverseFind = (nodeArray: NodeItem<T>[]): NodeItem<T> | null => {
            for (const node of nodeArray) {
                if (node.fetchKey == pKey) {
                    return node;
                }

                if (node.hasNodes) {
                    const foundNode: NodeItem<T> | null = traverseFind(node.details);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
            return null;
        };
        return traverseFind(this._dataObject.nodeData.root);
    }

    // --- DataObject overrides ---
    /**
     * DataObject.load override
     * @ignore
     */
    async load(...[pOptions]: Parameters<DataObject<T>['load']>): ReturnType<DataObject<T>['load']> {
        // if (this._dataObject.state.isLoading) { return; }
        if (this._levelConfigurations.length == 0 || this._levelConfigurations.every(config => config.disabled)) {
            this.disable();
            return this._dataObject_load(pOptions);
        }
        this._dataObject.state.rowCount = null;
        const expandedKeys = this._localStorage.getStoredValue() ?? undefined;
        if (this.loadFullStructure) {
            // Load all of the necessary data and create the full node structure on client
            this._dataObject.state.isLoading = true;
            const data = await this.getData();
            if (data.length > 20000) {
                logger.warn(`${this._dataObject.id}.nodeData: full structure load returned more than 20 000 rows. Switching to server side structure construction`);
                this.loadFullStructure = false;
                this._dataObject.state.isLoading = false;
                return this.load(pOptions);
            }
            const setupLevel = (pLevel: number, pNode: NodeItem<T> | null, pData: T[]) => {
                const configuration = this._levelConfigurations[pLevel];
                if (configuration == null || configuration.disabled) { return []; }
                const configurationOutput = configuration.getNodes(pData, {
                    startingLevel: pNode ? pNode.level + 1 : 0,
                    expandedKeys: expandedKeys,
                    autoExpandOnFilter: this.autoExpandOnFilter && !!this._dataObject.recordSource.filterString
                });
                const entries = configurationOutput.boundry;
                if (pNode) {
                    configurationOutput.root.forEach(node => {
                        pNode.details.push(node);
                        node.getParent = () => pNode;
                        // if (pLevel === this.configurations.length - 1) {
                        //     node.details.forEach(detail => detail.level = pLevel + 1);
                        // }
                    });
                }

                entries.forEach(entry => {
                    const [node, subData] = entry;
                    if (pLevel < this._levelConfigurations.length - 1) {
                        setupLevel(pLevel + 1, node, subData);
                    } else if (pLevel === 0) {
                        // node.details.forEach(detail => detail.level = pLevel + 1);
                    }
                });
                return configurationOutput.root;
            }
            this._root = setupLevel(0, null, data);
        } else {
            // Load first level and fetch others on demand. Server side group by.
            const rootConfig = this._levelConfigurations.at(0);
            if (rootConfig == null) {
                throw new TypeError(`Something went wrong. ${this._dataObject.id}.nodeData has configurations but not at index 0`);
            }
            this._dataObject.state.isLoading = true;
            this._root = await rootConfig.getStructure({
                expandedKeys: expandedKeys,
                autoExpandOnFilter: this.autoExpandOnFilter
            });
        }
        this.updateRowCount();

        if (this.configurations.length === 1 && this.configurations[0] instanceof HierarchyLevelConfiguration) {
            // Create new row if allowInsert is true, no data was loaded and the only configuration used is hierarchy
            if (this._dataObject.allowInsert && this.root.length === 0) {
                const item = this._dataObject.storage.data.find(x => x.isNewRecord);
                const node = this._levelConfigurations.at(0)?.createNode(item ?? {});
                if (node) {
                    this.root.push(node);
                }
            }
        } else if (this.configurations.every(config => config instanceof GroupByLevelConfiguration)) {
            this.deepestLevel = this.configurations.length;
        }
        this.update();
        this._dataObject.state.isLoading = false;
        if (!this._dataObject.state.isLoaded) {
            this._dataObject.state.isLoaded = true;
        }

        let setCurrentIndexPromise: Promise<any> | undefined = undefined;
        if (this._dataObject.selectFirstRowOnLoad) {
            if (this.root[0]?.isLoading) {
                setCurrentIndexPromise = this.root[0].loadingPromise?.then(() => {
                    const index = this.root[0].dataItem?.index;
                    if (index != null) {
                        this._dataObject.setCurrentIndex(index, true);
                    }
                });
            }
        }
        const options = this._dataObject.recordSource.getOptions();
        if (setCurrentIndexPromise) {
            await setCurrentIndexPromise;
        }

        this._dataObject.emit('DataLoaded', this._dataObject.data, options);

        return this._data;
    }
    /**
     * DataObject.recordSource.retrieve override
     * @ignore
     */
    async retrieve(pOptions: Partial<RecordSourceOptions>) {
        let options = this._dataObject.recordSource.getOptions();
        const hasFilterStringOverride = !!pOptions.filterString;

        if (pOptions) {
            pOptions = { ...options, ...pOptions }
        }
        const removeFilterString = !hasFilterStringOverride && this.configurations.some(x => {
            if (x instanceof HierarchyLevelConfiguration) {
                return x.requireParents ?? false;
            }
            return false;
        });
        if (removeFilterString) {
            delete pOptions.filterString;
        }

        let data: T[];
        if (this._dataObject.dataHandler instanceof DataHandler) {
            data = await this._dataObject.dataHandler.request('retrieve', pOptions);
        } else {
            data = await this._dataObject.dataHandler.retrieve(pOptions);
        }
        return data;
    }

    // ----------------------------

    /**
     * Helper method for programmatically setting group by on a data object
     * This method will add group by configuraionts for the provided fields
     */
    setGroupBy(pGroupBy: (Field<T> | Field<T>[])[], pOptions?: {
        dataGridControl?: DataGridControl
    }) {
        if (this.configurations.length) {
            this.configurations.splice(0, this.configurations.length);
        }
        const getOptions = (pGroup: typeof pGroupBy[0]) => {
            const options: NodeDataGroupByConfigurationOptions<T> = {
                type: 'groupBy'
            };

            if (Array.isArray(pGroup) && pGroup.length > 1) {
                options.fields = pGroup;
            } else {
                options.fieldName = Array.isArray(pGroup) ? pGroup[0] : pGroup;
            }

            if (pOptions?.dataGridControl && options.fieldName) {
                const pathColumn = pOptions.dataGridControl.dataColumns.columns.find(col => col.field === options.fieldName && col.groupPathField);
                if (pathColumn) {
                    options.pathField = pathColumn.groupPathField;
                    options.pathIdReplace = pathColumn.groupPathReplacePlaceholder;
                    options.pathMode = pathColumn.groupPathMode;
                }
            }

            return options;
        };

        pGroupBy.forEach(group => {
            this.addConfiguration(getOptions(group));
        });

        if (this.configurations.length > 0) {
            this.enable();
        } else {
            this.disable();
        }

        this.events.emit('SetGroupBy');
    }

    getField(pName: string) {
        return this._dataObject.fields[pName];
    }

    private _saveExpandedState() {
        const expandedKeys: Record<string, true> = {};
        ((this._data as unknown) as NodeItem<T>[]).filter(node => node.expanded).forEach(node => {
            expandedKeys[node.key] = true;
        });
        this._localStorage.storeValue(expandedKeys);
    }

    private _handleAfterDelete(_options: any, item: T) {
        const node: NodeItem<T> | undefined = (item as any)._getNode ? (item as any)._getNode() : undefined;
        if (node) {
            const parentNode = node.getParent();
            if (parentNode) {
                const nodeIndex = parentNode.details.findIndex(x => x.key === node.key);
                if (nodeIndex !== -1) {
                    parentNode.details.splice(nodeIndex, 1);
                } else {
                    logger.warn('Could not remove node, failed to find in parent', node);
                }
            } else {
                // Node is possibly at root, find the index and remove it
                const nodeIndex = this._root.findIndex(x => x.key === node.key);
                if (nodeIndex !== -1) {
                    this._root.splice(nodeIndex, 1);
                } else {
                    logger.warn('Could not remove node, failed to find in root', node);
                }
            }
        }
        this.update();
    }

    loadAllRows() {
        const promises: Promise<void>[] = [];
        const traverse = (node: NodeItem<T>) => {
            if (node.isLoading && node.loadingPromise) {
                promises.push(node.loadingPromise);
            }
            if (node.details?.length) {
                node.details.forEach(detail => traverse(detail));
            }
        }
        this.root.forEach(node => traverse(node));
        return Promise.all(promises);
    }

}

/** Node item used in NodeData structures */
export class NodeItem<T extends ItemModel = ItemModel> {
    /** Unique key used for retrieving the full data item */
    protected _fetchKey?: string | number;
    /** Get the NodeData instance for this item */
    protected _getNodeData: () => NodeData<T>;
    /** Get the configuration this NodeItem belongs to */
    protected _getConfiguration: () => INodeDataLevelConfiguration<T>;
    /** Function used to fill in the DataItem value */
    protected _refreshItem?: (pKey: string | number) => Promise<DataItemModel<T> | undefined>;
    /** Function called before inner load. Used for placeholder items */
    protected _beforeLoad?: (pNode: NodeItem<T>) => Promise<void>;
    /** Function used to fill in the summary values for non DataItem nodes */
    protected _getSummaryValues?: (pDetails: NodeItem<T>[], pNode: NodeItem<T>) => Promise<T>;
    /** Loading promise created when isLoading is accessed */
    protected _loadingPromise?: Promise<DataItemModel<T> | T | undefined | void>;
    /** Get combined filter string for loading details of this item */
    protected _getFilterString?: () => string;
    protected _canCreateNodes: boolean;
    /** Added setters/getters passthrough keys for the inner data item */
    protected _itemKeys: Set<string> = new Set();
    protected _dataItem?: DataItemModel<T>;
    protected _summaryItem?: T;
    /** Field used by NodeColumn to display a value */
    protected _displayField?: string;
    protected _displayFormatFunction?: ((pValue: any) => any) | null;
    
    protected _partialItem?: Partial<T>;
    

    /** Get the parent node of this item */
    getParent: () => NodeItem<T> | undefined = () => undefined;
    expanded: boolean = false;
    details: NodeItem<T>[] = [];
    level: number = 0;
    /** Index of this node in the flattened out display array */
    displayIndex: number | undefined;
    protected _key: string[] = [];
    protected _error?: string;
    protected _isSelected = false;

    get displayValue() {
        if (this._displayField) {
            const formatFunction = this._getDisplayFormatFunction();
            const value = (this as any)[this._displayField] as any;
            return formatFunction
                ? formatFunction(value)
                : value;
        } else {
            return undefined;
        }
    }
    get displayField() { return this._displayField; }
    /** Group key in string form */
    get key() {
        return this._key.map(x => `${x}`).join('/');
    }
    /** Group key in array form */
    get keyArray() {
        return this._key;
    }
    get hasNodes() {
        return this.details.length > 0 || this._summaryItem?._count;
    }
    get count(): number {
        return this._summaryItem?._count || this.details.length + this.details.reduce((sum, node) => sum + node.count, 0);
    }
    get hasSelected() {
        return this.hasNodes && this.details.some(x => x.isSelected);
    }
    /** Indicates that this Node has the ability to create detail or sibling nodes */
    get canCreateNodes() { return this._canCreateNodes; }
    set canCreateNodes(pValue) { this._canCreateNodes = pValue; }
    /** Indicator for other controls used when determining if received item is DataItem or NodeItem */
    get isNode() { return true; }
    /** DataItem of this node */
    get dataItem() { return this._dataItem; }
    /** Summary values of this node */
    get summaryItem() { return this._summaryItem; }
    /** Indicates that this Node is a summary item and has no real DataItem attached to it */
    get isSummaryItem() { return this._getSummaryValues != null || this._summaryItem != null; }
    /** Get combined filter string for loading details of this item */
    get getFilterString() { return this._getFilterString; }
    /** Unique key used to retrieve values for this item */
    get fetchKey() { return this._fetchKey; }

    // --- DataItemModel Passthrough properties ---
    get primKey() { return this._dataItem?.primKey }
    get isLoading() {
        if (this._summaryItem) {
            return false
        } else if (this._dataItem == null) {
            this.loadItem();
            return true;
        } else {
            return this._dataItem.isLoading;
        }
    }
    get error() { return this._error ?? this._dataItem?.error; }
    get index() { return this._dataItem?.index; }
    get dataObjectId() { return this._dataItem?.dataObjectId; }
    get appId() { return this._dataItem?.appId; }
    get oldValues() { return this._dataItem?.oldValues; }
    get item() { return this._dataItem?.item; }
    get defaultValues() { return this._dataItem?.defaultValues; }
    get hasChanges() { return this._dataItem?.hasChanges; }
    get isDeleting() { return this._dataItem?.isDeleting; }
    get isEmpty() { return this._dataItem?.isEmpty; }
    get isNewRecord() { return this._dataItem?.isNewRecord; }
    get isBatchRecord() { return this._dataItem?.isBatchRecord; }
    get loadingPromise() { return this._dataItem?.loadingPromise ?? this._loadingPromise; }
    get state() { return this._dataItem?.state; }
    get changes() { return this._dataItem?.changes; }
    get current() { return this._dataItem?.current ?? false; }
    set current(value: boolean) {
        if (this._dataItem) {
            this._dataItem.current = value;
        }
    }
    get isSelected() { return this._dataItem?.isSelected ?? this._isSelected; }
    set isSelected(value) {
        this._isSelected = value;
        if (this._dataItem) {
            this._dataItem.isSelected = value;
        }
        const nodeData = this._getNodeData();
        if (!nodeData.disableDetailsMultiSelect) {
            this.details.forEach(node => node.isSelected = value);
        }
    }
    save() { return this._dataItem?.save(); }
    delete() { return this._dataItem?.delete(); }
    cancelChanges() { return this._dataItem?.cancelChanges(); }
    reset() { return this._dataItem?.reset(); }
    extendItem(...args: Parameters<DataItem<T>['extendItem']>) { return this._dataItem?.extendItem(...args); }
    updateError(...args: Parameters<DataItem<T>['updateError']>) { return this._dataItem?.updateError(...args); }
    removeError(...args: Parameters<DataItem<T>['removeError']>) { return this._dataItem?.removeError(...args); }

    /** Properties passthrough */
    get properties() { return this._dataItem?.['properties']; }
    get propertiesRows() { return this._dataItem?.['propertiesRows']; }
    get propertiesRowsArray() { return this._dataItem?.['propertiesRowsArray']; }
    get propertiesJSON() { return this._dataItem?.['propertiesJSON']; }
    get isPropertiesLoading() { return this._dataItem?.['isPropertiesLoading']; }
    // --------------------------------------------

    // --- Compatibility for previous node structures ---
    get o_hasDetails() { return this.hasNodes; }
    get o_level() { return this.level; }
    get o_groupHeaderRow() { return this.isSummaryItem; }
    // ---------

    constructor(pOptions: {
        key?: any[];
        fetchKey?: string | number;
        getNodeData: () => NodeData<T>;
        getConfiguration: () => INodeDataLevelConfiguration<T>;
        refreshItem?: (pKey: string | number) => Promise<DataItemModel<T> | undefined>
        getSummaryValues?: (pDetails: NodeItem<T>[], pNode: NodeItem<T>) => Promise<T>,
        getFilterString?: () => string,
        beforeLoad?: (pNode: NodeItem<T>) => Promise<void>,
        summaryItem?: T,
        passthroughKeys: string[],
        displayField?: string,
        item?: DataItemModel<T>,
        partialItem?: Partial<T>
    }) {
        if (pOptions.key) {
            this._key = pOptions.key;
        }
        if (pOptions.item) {
            this._dataItem = pOptions.item;
        }
        if (pOptions.fetchKey) {
            this._fetchKey = pOptions.fetchKey;
        }
        if (pOptions.summaryItem) {
            this._summaryItem = pOptions.summaryItem;
        }
        if (pOptions.displayField) {
            this._displayField = pOptions.displayField;
        }
        if (pOptions.beforeLoad) {
            this._beforeLoad = pOptions.beforeLoad;
        }
        if (pOptions.getFilterString) {
            this._getFilterString = pOptions.getFilterString;
        }
        this._getNodeData = pOptions.getNodeData;
        this._getConfiguration = pOptions.getConfiguration;
        this._refreshItem = pOptions.refreshItem;
        this._getSummaryValues = pOptions.getSummaryValues;
        this._partialItem = pOptions.partialItem;
        pOptions.passthroughKeys.forEach(key => this._itemKeys.add(key));

        this._canCreateNodes = false;
    }

    expand() {
        if (this.expanded) { return; }
        const nodeData = this._getNodeData();
        this.expanded = true;
        nodeData.update();
    }

    collapse() {
        if (!this.expanded) { return; }
        const nodeData = this._getNodeData();
        this.expanded = false;
        nodeData.update();
    }

    expandTo() {
        const expandTraverse = (node: NodeItem<T>) => {
            node.expanded = true;
            const parent = node.getParent();
            if (parent) {
                expandTraverse(parent);
            }
        };
        const parent = this.getParent();
        if (parent) {
            expandTraverse(parent);
            this._getNodeData().update();
        }
        this._getNodeData().events.emit('ExpandedToNode', this);
    }

    getConfiguration() {
        return this._getConfiguration();
    }

    async loadItem() {
        if (this._loadingPromise) { return; }
        if (this._beforeLoad) {
            this._loadingPromise = this._beforeLoad(this);
            await this._loadingPromise;
        }
        if (this._refreshItem) {
            if (!this.fetchKey) { return; }
            this._loadingPromise = this._refreshItem(this.fetchKey);
            const item = await this._loadingPromise;
            if (item instanceof DataItem) {
                this._dataItem = item;
                this._dataItem.isSelected = this._isSelected;
                // TODO(Augustas): Think of a better way to get node from data item
                (this._dataItem as any)['_getNode'] = () => this;

                this._canCreateNodes = this._getConfiguration().canCreateNodes(this);
            } else {
                this._error = 'Failed to retrieve item';
            }
        } else if (this._getSummaryValues) {
            this._loadingPromise = this._getSummaryValues(this.details, this);
            const item = await this._loadingPromise;
            if (item) {
                this._summaryItem = item;
            } else {
                this._error = 'Failed to retrieve summary values';
            }
        }
    }
    indent() {
        const nodeData = this._getNodeData();
        const config = this.getConfiguration();
        const parent = this.getParent();
        if (parent) {
            const index = parent.details.findIndex(x => x.key === this.key);
            if (index === -1 || parent.details[index - 1] == null) { return; }
            parent.details.splice(index, 1);
            const sibling = parent.details[index - 1];
            this.getParent = () => sibling;
            config.updateNodeParent(this, sibling);
            this.level = sibling.level + 1;
            sibling.details.push(this);
            sibling.expanded = true;
        } else {
            const index = nodeData.root.findIndex(x => x.key === this.key);
            if (index === -1 || nodeData.root[index - 1] == null) { return; }
            nodeData.root.splice(index, 1);
            const sibling = nodeData.root[index - 1];
            this.getParent = () => sibling;
            config.updateNodeParent(this, sibling);
            this.level = sibling.level + 1;
            sibling.details.push(this);
            sibling.expanded = true;
        }
        const traverseDetails = (node: NodeItem<T>, level: number) => {
            node.level = level;
            if (node.hasNodes) {
                node.details.forEach(detail => traverseDetails(detail, level + 1));
            }
        };
        this.details.forEach(detail => traverseDetails(detail, this.level + 1));
        this.dataItem?.save();
        nodeData.update();
    }

    outdent() {
        const nodeData = this._getNodeData();
        const config = this.getConfiguration();
        const parent = this.getParent();
        if (parent == null) { return; }
        const nodeIndex = parent.details.findIndex(x => x.key === this.key);
        if (nodeIndex === -1) { return; }
        parent.details.splice(nodeIndex, 1);

        const parentScope = parent.getParent();
        if (parentScope) {
            const index = parentScope.details.findIndex(x => x.key === parent.key);
            if (index === -1 || parentScope.details[index] == null) { return; }
            this.getParent = () => parentScope;
            config.updateNodeParent(this, parentScope);
            this.level = parentScope.level + 1;
            parentScope.details.splice(index + 1, 0, this);
            parentScope.expanded = true;
        } else {
            const index = nodeData.root.findIndex(x => x.key === parent.key);
            if (index === -1 || nodeData.root[index] == null) { return; }
            this.getParent = () => undefined;
            config.updateNodeParent(this, parentScope);
            this.level = 0;
            nodeData.root.splice(index + 1, 0, this);
        }
        const traverseDetails = (node: NodeItem<T>, level: number) => {
            node.level = level;
            if (node.hasNodes) {
                node.details.forEach(detail => traverseDetails(detail, level + 1));
            }
        };
        this.details.forEach(detail => traverseDetails(detail, this.level + 1));
        this.dataItem?.save();
        nodeData.update();
    }

    addDetail(pItem?: T | DataItemModel<T> | NodeItem<T>) {
        if (!this.canCreateNodes || this.isLoading) { return; }
        const config = this.getConfiguration();

        let node: NodeItem<T> | null = null;
        if (pItem?.isNode) {
            node = pItem as NodeItem<T>;
        } else {
            node = config.createNode({
                item: pItem as T | DataItemModel<T> | undefined
            });
            node.level = this.level + 1;
        }
        if (node) {
            node.getParent = () => this;
            config.updateNodeParent(node, this);
            this.details.push(node);
            if (!this.expanded) { this.expanded = true; }
            const nodeData = this._getNodeData();
            nodeData.update();
            nodeData.events.emit('NodeAdded', node);

        }
    }

    addSibling(pItem?: T | DataItemModel<T> | NodeItem<T>, pOptions?: {
        above?: boolean
    }) {
        if (!this.canCreateNodes || this.isLoading) { return; }
        const config = this.getConfiguration();

        let node: NodeItem<T> | null = null;
        if (pItem?.isNode) {
            node = pItem as NodeItem<T>;
        } else {
            node = config.createNode({
                item: pItem as T | DataItemModel<T> | undefined
            });
            node.level = this.level;
        }
        if (node) {
            const parent = this.getParent();
            node.getParent = () => parent;
            const insertAbove = pOptions?.above ?? false;
            if (parent) {
                config.updateNodeParent(node, parent);
                const index = parent.details.findIndex(x => x.key === this.key);
                parent.details.splice(insertAbove ? index : index + 1, 0, node);
                const nodeData = this._getNodeData();
                nodeData.update();
                nodeData.events.emit('NodeAdded', node);
            } else {
                const nodeData = this._getNodeData();
                config.updateNodeParent(node);
                const index = nodeData.root.findIndex(x => x.key === this.key);
                nodeData.root.splice(insertAbove ? index : index + 1, 0, node);
                nodeData.update();
                nodeData.events.emit('NodeAdded', node);
            }
        }
    }

    hasSibling(pBefore = false) {
        const parent = this.getParent();
        if (parent) {
            const index = parent.details.findIndex(x => x.key === this.key);
            if (index === -1) { return false; }
            return pBefore ? parent.details[index - 1] != null : parent.details[index + 1] != null;
        } else {
            const nodeData = this._getNodeData();
            const index = nodeData.root.findIndex(x => x.key === this.key);
            if (index === -1) { return false; }
            return pBefore ? nodeData.root[index - 1] != null : nodeData.root[index + 1] != null;
        }
    }


    /** Update getters and setters passthrough to the inner DataItem */
    updateItemPassthrough() {
        this._itemKeys.forEach(key => {
            Object.defineProperty(this, key, {
              get() { return this._dataItem?.[key] ?? this._summaryItem?.[key] ?? this._partialItem?.[key]; },
                set(value: any) {
                    if (this._dataItem) {
                        this._dataItem[key] = value;
                    }
                }
            });
        });
        //     if (this._dataItem == null) {
        //         this._itemKeys.forEach((key) => {
        //             delete (this as any)[key];
        //         });
        //         this._itemKeys.clear();
        //         return;
        //     }
        //     Object.keys(this._dataItem.item).forEach(key => {
        //         if (this._itemKeys.has(key)) { return; }
        //         Object.defineProperty(this, key, {
        //             get() { console.log(this, this._dataItem); return this._dataItem[key]; },
        //             set(value: any) { console.log(this, this[key]); this._dataItem[key] = value; }
        //         });
        //         this._itemKeys.add(key);
        //     });
        // }
    }

    protected _getDisplayFormatFunction() {
        if (this._displayFormatFunction) {
            return this._displayFormatFunction;
        } else if (this._displayFormatFunction === undefined) {
            const config = this.getConfiguration();
            this._displayFormatFunction = config.formatDisplayValue ?? null;
        }
        return this._displayFormatFunction;
    }
}

export interface INodeDataLevelConfiguration<T extends ItemModel = ItemModel> {
    ui: {
        title: string,
        type: string,
    }
    level: number;
    key: string;
    type: 'groupBy' | 'hierarchy';

    disabled: boolean;
    getStructure(pOptions: NodeDataStructureOptions, pNode?: NodeItem<T>): Promise<NodeItem<T>[]>;
    createNode(pOptions: NewNodeOptions<T>): NodeItem<T>;
    canCreateNodes(pNode: NodeItem<T>): boolean;
    updateNodeParent(pNode: NodeItem<T>, pParent?: NodeItem<T>): void;
    getConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined;
    /**
     * Get required configuration fields
     * @v2
     */
    getRequiredFields: () => string[];
    /**
     * @param pData - already filtered data
     * @v2
     */
    getNodes: (pData: T[], pOtions: NodeDataStructureOptions) => {
        root: NodeItem<T>[],
        boundry: [NodeItem<T>, T[]][]
    };
    /**
     * Get placeholder node
     * @v2
     */
    getPlaceholder: (pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) => NodeItem<T>;

    formatDisplayValue?: (pValue: any) => any ;
}

export class GroupByLevelConfiguration<T extends ItemModel = ItemModel> implements INodeDataLevelConfiguration<T> {
    private _dataObject: DataObject<T>;
    private _field?: string & keyof T;
    private _fields?: (string & keyof T)[];
    private _aggregates?: NodeDataGroupByConfigurationOptions<T>['aggregates']
    private _key: string;
    private _disabled = false;
    private _pathField?: string;
    private _pathIdReplaceFn?: (pPathId: string | number) => Promise<string>;
    private _pathMode = false;

    level = 0;
    getConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined;

    get field() {
        return this._field;
    }
    get fields() {
        return this._fields;
    }
    get ui() {
        return {
            title: this.isMultiField
                ? this._fields!.map(field => this._dataObject.fields[field]?.caption ?? field).join(' / ')
                : this._dataObject.fields[this.field!]?.caption ?? this._field!,
            type: $t('Group By')
        };
    }
    get key() {
        return this._key;
    }
    get isMultiField() {
        return this._fields != null;
    }

    get disabled() { return this._disabled; }
    set disabled(pValue) { this._disabled = pValue; }

    get pathMode() { return this._pathMode; }
    set pathMode(pValue) { this._pathMode = pValue; }

    get pathField() { return this._pathField; }

    formatDisplayValue?: INodeDataLevelConfiguration<T>['formatDisplayValue'];

    readonly type = 'groupBy'

    constructor(pDataObject: DataObject<T>, pOptions: NodeDataGroupByConfigurationOptions<T>, pGetConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined) {
        this._dataObject = pDataObject;
        this._field = pOptions.fieldName;
        this._fields = pOptions.fields;
        this._aggregates = pOptions.aggregates;
        this.getConfigurationForLevel = pGetConfigurationForLevel;
        this._key = window.crypto.randomUUID();
        this._pathField = pOptions.pathField;
        this._pathIdReplaceFn = pOptions.pathIdReplace;
        this._pathMode = pOptions.pathMode ?? false;
    }

    updateAggregates(pAggregates: NodeDataGroupByConfigurationOptions<T>['aggregates']) {
        this._aggregates = pAggregates;
    }

    /** Used in full structure mode */
    getNodes(pData: T[], pOptions: NodeDataStructureOptions) {
        const isLastConfiguration = this._dataObject.nodeData.configurations.length - 1 === this.level;
        const fields: (keyof T & string)[] = [];
        for (let i = 0; i <= this.level; i++) {
            const config = this.getConfigurationForLevel(i);
            if (config instanceof GroupByLevelConfiguration) {
                if (config.isMultiField) {
                    config.fields!.forEach(field => fields.push(field));
                } else {
                    fields.push(config.field!);
                }
            }
        }
        let result: [NodeItem<T>, T[]][] = [];
        let resultRoot: NodeItem<T>[] = [];
        const groups = new Map<string, T[]>();
        const summaries = new Map<string, T>();

        const linkedPaths = new PathNode<string>('root');

        const assignPath = (pScope: Map<string, PathNode<string>>, pValue: string, pSummary: T) => {
            if (!pScope.has(pValue)) { pScope.set(pValue, new PathNode(pValue)); summariesByPath.set(pValue, pSummary) }
            return pScope.get(pValue)!.children;
        }

        const nodesByPathMap = new Map<string, [NodeItem<T>, number]>();
        const summariesByPath = new Map<string, T>();
        const assignNodeToMap = (pItem: T, pNode: NodeItem<T>, pIndex: number) => {
            const pathString = (pItem[this._pathField!] as string) ?? '//'; 
            const path = pathString.split('/').slice(1, -1);
            const pathId = path.at(-1)!;
            nodesByPathMap.set(pathId, [pNode, pIndex]);
        }

        pData.forEach(item => {
            const key = JSON.stringify(this._getKey(item, fields));
            if (!groups.has(key)) { groups.set(key, []); }
            groups.get(key)!.push(item);
            summaries.set(key, item);
            if (this.pathMode && this._pathField) {
                const pathString = (item[this._pathField!] as string) ?? '//'; 
                const path = pathString.split('/').slice(1, -1);
                let activeScope = linkedPaths.children;
                path.forEach((id) => {
                    activeScope = assignPath(activeScope, id, item);
                });
            }
        });

        Array.from(groups.entries()).forEach((entry) => {
            const [key, data] = entry;
            const summary = summaries.get(key)!;
            const item: T = {} as T;
            const aggregates = this._aggregateData(data);
            const passthroughKeys = new Set<string>();
            if (aggregates) {
                Object.entries(aggregates).forEach(([key, value]) => {
                    item[key as keyof T] = value;
                    passthroughKeys.add(key);
                });
            }
            fields.forEach(field => {
                item[field] = summary[field];
                passthroughKeys.add(field);
            });
            const node = new NodeItem({
                key: this._getKey(item, fields),
                summaryItem: item,
                displayField: this._field,
                getSummaryValues: () => Promise.resolve(item),
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                passthroughKeys: Array.from(passthroughKeys)
            });
            node.updateItemPassthrough();
            if (pOptions.expandedKeys && pOptions.expandedKeys[node.key]) {
                node.expanded = true;
            } else if (pOptions.autoExpandOnFilter) {
                node.expanded = true;
            }
            if (this.pathMode && this._pathField) {
                assignNodeToMap(summary, node, result.length);
            }
            result.push([node, data])
            if (pOptions.startingLevel) {
                node.level = pOptions.startingLevel
            }
            resultRoot.push(node);
        });
        if (isLastConfiguration) {
            result.forEach(entry => {
                const [node, data] = entry;
                data.forEach(item => {
                    const detailNode = new NodeItem<T>({
                        key: [...node.keyArray, item.PrimKey],
                        fetchKey: item.PrimKey,
                        getNodeData: () => this._dataObject.nodeData,
                        getConfiguration: () => this,
                        refreshItem: (primKey: any) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
                            appendRowCount: false,
                            returnExisting: true
                        }),
                        passthroughKeys: this._dataObject.fields.fields.map(x => x.name)
                    });
                    detailNode.updateItemPassthrough();
                    detailNode.level = node.level + 1;
                    node.details.push(detailNode);
                    detailNode.getParent = () => node;
                });
            });
        }

        if (this._pathField && this._pathMode) {
            const newTree: NodeItem<T>[] = []
            let activeKey: string[] = [];
            let activeNode: NodeItem<T> | null = null;
            let activeScope = newTree;
            const recursiveNodeReplace = (pNode: PathNode<string>, pLevel = 0) => {
                const subNodes = pNode.childrenArray; // Subnodes of the current PathNode (aka directory)
                const scopeCopy = activeScope; // Reference cop of the current active directory
                const activeNodeCopy = activeNode; // Reference copy of the current parent node
                const activeKeyCopy = [...activeKey]; // Copy of the current active key
                subNodes.forEach(entry => {
                    const key = entry.value!;
                    if (key === '') {
                        if (nodesByPathMap.has(key)) {
                            const [node, index] = nodesByPathMap.get(key)!;
                            activeScope.push(...node.details.map((detail) => {
                                detail.level = pLevel;
                                return detail;
                            }));
                            nodesByPathMap.delete(key);
                        }
                        return;
                    }
                    activeKey.push(key);
                    const pathItem = new NodeItem({
                        key: [...activeKey],
                        getSummaryValues: async (_pDetail) => {
                            let display = key;
                            if (this._pathIdReplaceFn) {
                                display = await this._pathIdReplaceFn(key);
                            };
                            return {
                                o365_Path_ID: key,
                                o365_Path_Display: display,
                                ...summariesByPath.get(key)
                            } as any;
                        },
                        displayField: 'o365_Path_Display',
                        getNodeData: () => this._dataObject.nodeData,
                        getConfiguration: () => this,
                        passthroughKeys: ['o365_Path_ID', 'o365_Path_Display', ...this._dataObject.fields.fields.map(x => x.name)]
                    });
                    if (pOptions.expandedKeys && pOptions.expandedKeys[pathItem.key]) {
                        pathItem.expanded = true;
                    } else if (pOptions.autoExpandOnFilter) {
                        pathItem.expanded = true;
                    }
                    pathItem.level = pLevel;
                    pathItem.updateItemPassthrough();
                    activeScope.push(pathItem);
                    if (entry.childrenArray.length > 0) {
                        activeScope = pathItem.details;;
                        activeNode = pathItem;
                        recursiveNodeReplace(entry, pLevel + 1);
                    }
                    if (nodesByPathMap.has(key)) {
                        const [node, index] = nodesByPathMap.get(key)!;
                        const parent = pathItem;
                        pathItem.details.push(...node.details.map((detail) => {
                            detail.getParent = () => parent;
                            detail.level = pLevel + 1;
                            return detail;
                        }));
                        result[index][0] = parent;
                        nodesByPathMap.delete(key);
                    } else {
                        // pathItem.expanded = true;
                    }
                    activeKey = [...activeKeyCopy];
                    activeScope = scopeCopy;
                    activeNode = activeNodeCopy;
                });
            }
            recursiveNodeReplace(linkedPaths, pOptions.startingLevel ?? this.level);
            resultRoot = newTree;
        }

        return {
            root: resultRoot,
            boundry: result
        };
    }


    /** Server side (SQL group by) */
    async getStructure(pOptions: NodeDataStructureOptions, pNode?: NodeItem<T>) {
        const isLastConfiguration = this._dataObject.nodeData.configurations.length - 1 === this.level || this._dataObject.nodeData.configurations[this.level + 1]?.disabled;
        const options: RecordSourceOptions & Partial<IRetrieveOptions> = this._dataObject.recordSource.getOptions();
        this._dataObject.recordSource.updatePreviousWhereClause();
        options.skipClientSideHandlerCheck = true;
        options.maxRecords = -1;
        options.skip = 0;
        options.loadRecents = undefined;
        options.fields = [];

        const parentLevel = pNode?.level ?? -1;
        const parentFilterString = pNode?.getFilterString ? pNode.getFilterString() : undefined;

        if (parentFilterString) {
            if (options.whereClause) {
                options.whereClause = `${options.whereClause} AND ${parentFilterString}`;
            } else {
                options.whereClause = parentFilterString;
            }
        }

        const keyFields: string[] = [];
        for (let i = 0; i <= this.level; i++) {
            const config = this.getConfigurationForLevel(i);
            if (config instanceof GroupByLevelConfiguration) {
                if (this.isMultiField) {
                    this.fields!.forEach(field => {
                        keyFields.push(field);
                    });
                } else {
                    keyFields.push(config.field!);
                }
            }
        }
        options.fields = [
            { name: 'PrimKey', alias: '_count', aggregate: 'COUNT' }
        ];
        keyFields.forEach((field, index) => {
            options.fields!.push({ name: field, groupByOrder: index + 1 });
        });
        if (this._aggregates) {
            this._aggregates.forEach((field) => {
                const index = options.fields!.findIndex(x => x.name === field.name);
                if (index === -1) {
                    options.fields!.push(field);
                } else {
                    options.fields![index].groupByAggregate = field.aggregate;
                }
            });
        }

        const sortFields: (typeof options['fields']) = [];
        this._dataObject.recordSource.appendSortByFields(sortFields);
        sortFields.forEach(field => {
            const index = options.fields!.findIndex(x => x.name === field.name);
            if (index === -1) {
                if (this._dataObject?.fields[field.name]?.type == 'bit') { return; }
                options.fields!.push({
                    name: field.name,
                    alias: `_sort_${field.sortOrder}`,
                    aggregate: 'MIN',
                    sortOrder: field.sortOrder,
                    sortDirection: field.sortDirection
                });
            } else {
                options.fields![index].sortOrder = field.sortOrder;
                options.fields![index].sortDirection = field.sortDirection;
            }
        });

        const data = (await this._dataObject.dataHandler.retrieve(options)) as (T & { _count: number })[];
        const result: NodeItem<T>[] = [];
        data.forEach(item => {
            const node = new NodeItem({
                key: keyFields.map(field => item[field]),
                getFilterString: () => keyFields.map(field => `ISNULL(${field}, '') = '${item[field] ?? ''}'`).join(' AND '),
                summaryItem: item,
                displayField: this._field,
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                passthroughKeys: options.fields!.map(field => field.name)
            });
            node.level = parentLevel + 1;
            node.updateItemPassthrough();
            if (pOptions.expandedKeys && pOptions.expandedKeys[node.key]) {
                node.expanded = true;
            } else if (pOptions.autoExpandOnFilter && options.filterString) {
                node.expanded = true;
            }
            if (isLastConfiguration) {
                const placeholderNode = new NodeItem({
                    key: [...keyFields.map(field => item[field]), '<PLACEHOLDER>'],
                    getNodeData: () => this._dataObject.nodeData,
                    beforeLoad: (pNode) => this._replaceDataItemPlaceholder(pNode),
                    getFilterString: () => keyFields.map(field => `iSNULL(${field},'') = '${item[field] ?? ''}'`).join(' AND '),
                    getConfiguration: () => this,
                    passthroughKeys: []
                });
                placeholderNode.getParent = () => node;
                placeholderNode.level = node.level + 1;
                node.details.push(placeholderNode);
            } else {
                const detailConfiguration = this._dataObject.nodeData.configurations[this.level + 1];
                if (detailConfiguration) {
                    const placeholderItem = detailConfiguration.getPlaceholder(node, pOptions);
                    placeholderItem.getParent = () => node;
                    node.details.push(placeholderItem);
                }
            }
            result.push(node);
        });
        return result;
    }

    getPlaceholder(pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) {
        if (pNode.getFilterString == null) { throw new Error(`Can't load placeholder with no filter string`); }
        const placeholderNode: NodeItem<T> = new NodeItem<T>({
            key: [...pNode.keyArray, '<PLACEHOLDER>'],
            getNodeData: () => this._dataObject.nodeData,
            getFilterString: pNode.getFilterString,
            beforeLoad: (pNode) => this._replacePlaceholder(pNode, pOptions),
            getConfiguration: () => this,
            passthroughKeys: []
        });
        placeholderNode.level = pNode.level + 1;
        return placeholderNode;
    }

    private async _replacePlaceholder(pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) {
        const parentNode = pNode.getParent();
        if (parentNode == null) { throw new Error(`Placeholder item does not have a parent`); }
        const nodes = await this.getStructure(pOptions, parentNode);
        parentNode.details.splice(0, parentNode.details.length);
        nodes.forEach(node => {
            node.getParent = () => parentNode;
            parentNode.details.push(node);
        });
        this._dataObject.nodeData.update();
    }

    private async _replaceDataItemPlaceholder(pNode: NodeItem<T>) {
        if (pNode.getFilterString == null) { throw new Error(`Can't load placeholder with no filter string`); }
        const parentNode = pNode.getParent();
        if (parentNode == null) { throw new Error('Placeholder item does not have a parent'); }
        parentNode.details.splice(0, parentNode.details.length);
        const filterString = pNode.getFilterString();
        const options = this._dataObject.recordSource.getOptions();
        options.fields = [{ name: 'PrimKey' }];
        options.skip = 0;
        options.maxRecords = -1;
        this._dataObject.recordSource.appendSortByFields(options.fields);
        if (options.whereClause) {
            options.whereClause = `(${options.whereClause}) AND ${filterString}`;
        } else {
            options.whereClause = filterString;
        }
        const data = await this._dataObject.recordSource.retrieve(options) as (T & { PrimKey: string })[];
        data.forEach((item) => {
            const detailNode = new NodeItem<T>({
                key: [...parentNode.keyArray, item.PrimKey],
                fetchKey: item.PrimKey,
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                refreshItem: (primKey: any) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
                    appendRowCount: false,
                    returnExisting: true
                }),
                passthroughKeys: this._dataObject.fields.fields.map(x => x.name)
            });
            detailNode.updateItemPassthrough();
            detailNode.getParent = () => parentNode;
            detailNode.level = parentNode.level + 1;
            parentNode.details.push(detailNode);
        });
        this._dataObject.nodeData.update();
    }

    updatePathSetings(pOptions: {
        pathField?: string,
        pathFn?: (pId: string | number) => Promise<string>
    }) {
        this._pathField = pOptions.pathField;
        this._pathIdReplaceFn = pOptions.pathFn;
    }

    canCreateNodes() {
        return false;
    }

    updateNodeParent() {
        throw new TypeError(`Not Implemented`);
    }

    createNode(): NodeItem<T> {
        throw new TypeError(`Not Implemented`);
    }

    getRequiredFields() {
        const result: string[] = [];
        if (this.isMultiField) {
            this._fields!.forEach(field => result.push(field));
        } else {
            result.push(this.field!);
        }

        if (this._aggregates) {
            this._aggregates.forEach(aggregate => {
                result.push(aggregate.name);
            });
        }

        if (this._pathField && this._pathMode) {
            result.push(this._pathField);
        }

        return result;
    }

    private _getKey<T2 extends object = object>(pItem: T2, pKeys: string[]) {
        return pKeys.map((key) => {
            const value = (pItem as any)[key]
            if (typeof value == 'string') {
                return value.toLocaleLowerCase().trim();
            } else {
                return value;
            }
        });
    }

    /**
     * Client side aggregation used when entire structure is being loaded
     */
    private _aggregateData(pData: T[]) {
        if (this._aggregates == null || this._aggregates.length == 0) { return null; }
        const aggregateFunctions = {
            COUNT: (data: T[], field: string) => data.reduce((count, item) => {
                if (item[field] != null) { count++; }
                return count;
            }, 0),
            SUM: (data: T[], field: string) => data.reduce((sum, item) => {
                if (item[field] != null) { sum += item[field]; }
                return sum;
            }, 0),
            AVG: (data: T[], field: string) => {
                const dataSum = data.reduce((sum, item) => {
                    if (item[field] != null) { sum += item[field]; }
                    return sum;
                }, 0);
                return dataSum / data.length;
            },
            MIN: (data: T[], field: string) => data.reduce((min, item) => {
                if (item[field] != null) {
                    if (min == null || item[field] < min) {
                        min = item[field];
                    }
                }
                return min;
            }, null),
            MAX: (data: T[], field: string) => data.reduce((max, item) => {
                if (item[field] != null) {
                    if (max == null || item[field] > max) {
                        max = item[field];
                    }
                }
                return max;
            }, null)
        };

        const result: Partial<T> = {};
        this._aggregates.forEach(aggregate => {
            result[aggregate.name as keyof T] = aggregateFunctions[aggregate.aggregate](pData, aggregate.name) as any;
        });
        return result;
    }
}


class HierarchyLevelConfiguration<T extends ItemModel = ItemModel> implements INodeDataLevelConfiguration<T> {
    private _dataObject: DataObject<T>;
    private _parentField?: keyof T & string;
    private _idField?: keyof T & string;
    private _idPathField?: keyof T & string;
    private _bindToParent?: NodeDataHierarchyConfigurationOptions<T>['bindToParent'];
    private _parseKeyArray: (pItem: Partial<T>) => string[];
    private _getSummaryItem?: NodeDataHierarchyConfigurationOptions<T>['getSummaryItem'];
    private _aggregateSummaryNodes?: NodeDataHierarchyConfigurationOptions<T>['aggregateSummaryNodes'];
    private _additionalFields?: string[];
    private _requireParents: boolean;
    private _key: string;
    private _disabled = false;

    private _canCreateNodes?: (pNode: NodeItem<T>) => boolean;

    private _structureItems: Map<string, Record<string, any>> = new Map();

    private _retrieveByKey: BulkOperation<number | string, T[]>;

    level = 0;

    get ui() {
        return {
            title: this._idPathField ? $t(this._idPathField) : `${$t(this._parentField ?? '')}/${$t(this._idField ?? '')}`,
            type: $t('Hierarchy')
        };
    }
    get key() {
        return this._key;
    }

    get keyField() {
        return this._idField ?? this._dataObject.fields.uniqueField ?? 'PrimKey';
    }

    get disabled() { return this._disabled; }
    set disabled(pValue) { this._disabled = pValue; }

    readonly type = 'hierarchy';

    get requireParents() { return this._requireParents; }

    getConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined;

    constructor(pDataObject: DataObject<T>, pOptions: NodeDataHierarchyConfigurationOptions<T>, pGetConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined) {
        if (pOptions.idPathField == null && (pOptions.parentField == null || pOptions.idField == null)) {
            throw new TypeError('Either idPathField or parentField with idField must be provided ');
        }
        this._dataObject = pDataObject;

        this.getConfigurationForLevel = pGetConfigurationForLevel;

        this._idField = pOptions.idField;
        this._parentField = pOptions.parentField;
        this._idPathField = pOptions.idPathField;
        this._additionalFields = pOptions.additionalFields;
        this._getSummaryItem = pOptions.getSummaryItem;
        this._aggregateSummaryNodes = pOptions.aggregateSummaryNodes;

        this._canCreateNodes = pOptions.canCreateNodes;
        this._requireParents = pOptions.requireParents ?? false;
        if (pOptions.parseKeyArray) {
            this._parseKeyArray = pOptions.parseKeyArray;
        } else {
            this._parseKeyArray = (pItem) => {
                const idPath = pItem[this._idPathField!];
                const keyArray = idPath.split('/');
                keyArray.splice(0, 1);
                keyArray.splice(keyArray.length - 1, 1);
                return keyArray;
            };
        }
        this._key = window.crypto.randomUUID();

        this._retrieveByKey = new BulkOperation<number | string, T[]>({
            bulkOperation: async (pItems) => {
                const data = await this._dataObject.recordSource.bulkRetrieve(pItems.map(x => x.value), this.keyField);
                pItems.forEach(item => {
                    const rows = data.filter(x => x[this.keyField] === item.value);
                    item.res(rows);
                });
            },
            bulkSize: 500
        });
    }

    async refreshItemByKeyField(pKey: string | number) {
        const record = this._dataObject.storage.getItemByField(this.keyField, pKey as any);
        if (record == null) {
            const data = await this._retrieveByKey.addToQueue(pKey);
            if (data == null) {
                throw new Error(`Something went wrong when retrieving values for item with [${this.keyField}] = '${pKey}' binding. Didn't return any data`)
            } else if (data.length > 1) {
                throw new Error(`Something went wrong when retrieving values for item with [${this.keyField}] = '${pKey}' binding. Returned more than 1 row`);
            }
            const newRecord = this._dataObject.createNew(data?.[0], false, false);
            newRecord.state.isNewRecord = false;
            newRecord.reset();
            return newRecord
        } else {
            return record;
        }
    }

    async getStructure(pOptions: NodeDataStructureOptions, pNode?: NodeItem<T>) {
        const options: RecordSourceOptions & Partial<IRetrieveOptions> = this._dataObject.recordSource.getOptions();


        if (this._dataObject.recordSource.prevWhereClauseSingle !== this._dataObject.recordSource.whereClause) {
            this._structureItems.clear();
        }
        this._dataObject.recordSource.updatePreviousWhereClause();
        options.whereClause = this._dataObject.recordSource.whereClause;
        const contextFilter = this._dataObject.recordSource.getContextFilterString();
        if (contextFilter) {
            if (this._requireParents) {
                if (options.filterString) {
                    options.filterString += ` AND ${contextFilter}`;
                } else {
                    options.filterString = contextFilter;
                }
            } else {
                if (options.whereClause) {
                    options.whereClause += ` AND ${contextFilter}`;
                } else {
                    options.whereClause = contextFilter;
                }
            }
        }

        const parentLevel = (pNode?.level ?? -1) + 1;
        const parentFilterString = pNode?.getFilterString ? pNode.getFilterString() : undefined;
        if (parentFilterString) {
            if (options.whereClause) {
                options.whereClause = `${options.whereClause} AND ${parentFilterString}`;
            } else {
                options.whereClause = parentFilterString;
            }
        }

        options.skipClientSideHandlerCheck = true;
        options.maxRecords = -1;
        options.skip = 0;
        options.loadRecents = undefined;
        if (this._structureItems.size === 0 && this._requireParents) {
            options.filterString = undefined;
        }
        options.fields = [
            { name: this._dataObject.fields.uniqueField || 'PrimKey' }
        ];
        if (this._idPathField) {
            options.fields.push({ name: this._idPathField });
        } else if (this._idField && this._parentField) {
            options.fields.push({ name: this._idField });
            options.fields.push({ name: this._parentField });
        } else {
            throw new TypeError('Invalid configuration for tree structure retrieval');
        }

        // TODO(Augustas): Make sure even half configured sort by is included ( sortOrder without sortDirection)
        this._dataObject.recordSource.getSortOrder().forEach((sort, index) => {
            Object.entries(sort).forEach(([key, direction]) => {
                const field = options.fields!.find(x => x.name === key);
                if (field) {
                    field.sortOrder = index;
                    field.sortDirection = direction;
                } else {
                    options.fields!.push({
                        name: key,
                        sortOrder: index,
                        sortDirection: direction
                    });
                }
            });
        });

        if (this._additionalFields) {
            this._additionalFields.forEach(field => {
                if (options.fields!.findIndex(x => x.name === field) === -1) {
                    options.fields!.push({ name: field });
                }
            });
        }

        const data = await this._dataObject.dataHandler.retrieve(options);

        const root: NodeItem<T>[] = [];
        const keyMap: Record<string, NodeItem<T>> = {};
        const parentKeyMap: Record<string, string | undefined> = {};
        const populateParentRelations = (pKeyArr: string[]) => {
            if (pKeyArr.length === 1) {
                parentKeyMap[pKeyArr[0]] = undefined;
            } else {
                parentKeyMap[pKeyArr.at(-1)!] = pKeyArr.at(-2);
                populateParentRelations(pKeyArr.slice(0, pKeyArr.length - 1))
            }
        };

        const getKeyFromRecord = (item: any) => {
            if (item == null) { return undefined; }
            if (this._idPathField) {
                const idPath = `${item[this._idPathField]}`;
                if (!idPath) { return undefined; }
                const keyArray = this._parseKeyArray(item);
                if (this._getSummaryItem) {
                    populateParentRelations(keyArray);
                }
                // return keyArray.join('/');
                return keyArray.at(-1);
            } else {
                const key = item[this._idField];
                return !key ? undefined : `${key}`;
            }
        };
        const getParentKeyFromRecord = (item: any) => {
            if (item == null) { return undefined; }
            if (this._idPathField) {
                const idPath = `${item[this._idPathField]}`;
                if (!idPath) { return undefined; }
                const keyArray = this._parseKeyArray(item);
                if (keyArray.length === 1) { return undefined; }
                // return keyArray.slice(0, keyArray.length -1).join('/');
                return keyArray.at(-2);
            } else {
                const parentKey = item[this._parentField];
                return !parentKey ? undefined : `${parentKey}`;
            }
        };

        const constructNodeFromStorage = (key: string) => {
            if (!key) { return undefined; }
            const item = this._structureItems.get(key);
            if (item == null) {
                if (this._getSummaryItem) {
                    const node = new NodeItem<T>({
                        key: [key],
                        getConfiguration: () => this,
                        getNodeData: () => this._dataObject.nodeData,
                        passthroughKeys: this._dataObject.fields.fields.map(x => x.name),
                        getSummaryValues: async (pDetails, pNode) => {
                            const promises = pDetails.map(node => {
                                return new Promise<NodeItem<T>>(async (res) => {
                                    if (node.isLoading && node.loadingPromise) {
                                        await node.loadingPromise;
                                    }
                                    res(node);
                                });
                            });
                            const details = await Promise.all(promises);
                            return this._getSummaryItem!(details, pNode);
                        },
                    });
                    node.updateItemPassthrough();
                    keyMap[`${key}`] = node;
                    return node;
                } else {
                    return undefined;
                }
            }
            const node = new NodeItem<T>({
                key: [key],
                // primKey: item.PrimKey,
                fetchKey: item[this.keyField],
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                refreshItem: (pKey: string | number) => this.refreshItemByKeyField(pKey),
                // refreshItem: (primKey: string) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
                //     appendRowCount: false,
                //     returnExisting: true
                // }),
                passthroughKeys: this._dataObject.fields.fields.map(x => x.name),
                partialItem: item
            });
            node.updateItemPassthrough();
            keyMap[`${key}`] = node;

            return node;
        };

        // Construct node items for each record
        data.forEach(item => {
            const key = getKeyFromRecord(item);
            if (key == null) { return; }
            this._structureItems.set(key, item);
            // const parentKey = getParentKeyFromRecord(item);

            const node = new NodeItem<T>({
                key: [key],
                fetchKey: item[this.keyField],
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                refreshItem: (pKey: string | number) => this.refreshItemByKeyField(pKey),
                // refreshItem: (primKey: string) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
                //     appendRowCount: false,
                //     returnExisting: true
                // }),
                passthroughKeys: this._dataObject.fields.fields.map(x => x.name),
                partialItem: item
            });
            node.updateItemPassthrough();

            keyMap[`${key}`] = node;
        });

        const checkedKeys: Set<string> = new Set();
        const traverseToRoot = (key: string) => {
            if (checkedKeys.has(key)) { return; }
            checkedKeys.add(key);
            let parentKey: string | undefined = undefined;
            const item = this._structureItems.get(key);
            if (item == null || keyMap[key] == null) {
                if (this._getSummaryItem) {
                    parentKey = parentKeyMap[key];
                } else {
                    return;
                }
            } else {
                parentKey = getParentKeyFromRecord(item);
            }
            if (parentKey) {
                if (keyMap[parentKey]) {
                    const parentNode = keyMap[parentKey];
                    parentNode.details.push(keyMap[key]);
                    keyMap[key].getParent = () => parentNode;
                } else {
                    if (!this._requireParents) {
                        root.push(keyMap[key]);
                        return;
                    }
                    const parentNode = constructNodeFromStorage(parentKey);
                    if (parentNode) {
                        parentNode.details.push(keyMap[key]);
                        keyMap[key].getParent = () => parentNode;
                    } else {
                        if (this._requireParents) {
                            logger.warn(`${this._dataObject.id}: Could not assign node to a parent, missing parent item:`, item)
                            root.push(keyMap[key]);
                        } else {
                            root.push(keyMap[key]);
                        }
                    }
                }
                traverseToRoot(parentKey);
            } else {
                root.push(keyMap[key]);
            }
        };

        data.forEach(item => {
            const key = getKeyFromRecord(item);
            if (key == null) { return; }
            traverseToRoot(key);
        });

        if (this._aggregateSummaryNodes) {
            const traverseAggregate = (node: NodeItem<T>, aggregateArr: NodeItem<T>[] = []) => {
                if (node.isSummaryItem) { aggregateArr.push(node); }
                if (!node.isSummaryItem || node.details.length !== 1) {
                    node.details.forEach(detail => traverseAggregate(detail));
                } else {
                    node.details.forEach(detail => traverseAggregate(detail, aggregateArr));
                }

                if (aggregateArr.length > 1) {
                    const aggregatedKey = this._aggregateSummaryNodes!(aggregateArr);
                    const rootNode = aggregateArr[0];
                    const lastNode = aggregateArr.at(-1)!;
                    const parentNode = rootNode.getParent();
                    let rootIndex: number | null = null;

                    if (parentNode == null) {
                        rootIndex = root.findIndex(x => x.key === rootNode.key);
                    } else {
                        rootIndex = parentNode.details.findIndex(x => x.key === rootNode.key);
                    }
                    if (rootIndex === -1) { return; }

                    const aggregatedNode = new NodeItem<T>({
                        key: [aggregatedKey],
                        getConfiguration: () => this,
                        getNodeData: () => this._dataObject.nodeData,
                        passthroughKeys: this._dataObject.fields.fields.map(x => x.name),
                        getSummaryValues: async (pDetails) => {
                            const promises = pDetails.map(node => {
                                return new Promise<NodeItem<T>>(async (res) => {
                                    if (node.isLoading && node.loadingPromise) {
                                        await node.loadingPromise;
                                    }
                                    res(node);
                                });
                            });
                            const details = await Promise.all(promises);
                            return this._getSummaryItem!(details, node);
                        },
                    });
                    aggregatedNode.updateItemPassthrough();
                    if (parentNode) {
                        aggregatedNode.getParent = () => parentNode;

                    }
                    lastNode.details.forEach(detail => {
                        detail.getParent = () => aggregatedNode;
                        aggregatedNode.details.push(detail);
                    });

                    if (parentNode) {
                        parentNode.details.splice(rootIndex, 1, aggregatedNode);
                    } else {
                        root.splice(rootIndex, 1, aggregatedNode);
                    }

                }
            };

            root.forEach(node => traverseAggregate(node));
        }

        let deepestLevel = 0;
        // Final loop for updating levels and other properties that depend on the parent chain
        const updateLevels = (node: NodeItem<T>, level = 0, parentKey: string[] = []) => {
            if (level > deepestLevel) { deepestLevel = level; }
            node.level = level + parentLevel;
            for (let i = parentKey.length - 1; i >= 0; i--) {
                node.keyArray.unshift(parentKey[i]);
            }
            if ((pOptions.expandedKeys && pOptions.expandedKeys[node.key]) || (
                node.hasNodes && options.filterString && pOptions.autoExpandOnFilter
            )) {
                node.expanded = true;
            }
            node.details.forEach(detail => updateLevels(detail, level + 1, node.keyArray));
            _sortData(options, node.details, this._dataObject.fields);
        };
        _sortData(options, root, this._dataObject.fields);
        root.forEach(node => updateLevels(node));
        this._dataObject.nodeData.deepestLevel = deepestLevel;
        if (this._dataObject.nodeData.currentLevel > deepestLevel) {
            this._dataObject.nodeData.currentLevel = deepestLevel;
        }
        return root;
    }

    createNode(pOptions: NewNodeOptions<T>) {
        let item = pOptions.item
        if (!(item instanceof DataItem)) {
            item = this._dataObject.createNew(pOptions.item);
        }
        const node: NodeItem<T> = new NodeItem<T>({
            fetchKey: item[this.keyField],
            item: item as DataItemModel<T>,
            getNodeData: () => this._dataObject.nodeData,
            getConfiguration: () => this,
            refreshItem: (pKey: string | number) => this.refreshItemByKeyField(pKey),
            // refreshItem: (primKey: string) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
            //     appendRowCount: false,
            //     returnExisting: true
            // }),
            passthroughKeys: this._dataObject.fields.fields.map(x => x.name)
        });
        node.updateItemPassthrough();
        // TODO(Augustas): Need better way to add accessor node to item
        (item as any)['_getNode'] = () => node;

        return node;
    }

    canCreateNodes(pNode: NodeItem<T>) {
        let developerCondition = true;
        if (this._canCreateNodes) {
            developerCondition = this._canCreateNodes(pNode);
        }
        return this._dataObject.allowInsert && developerCondition &&
            (this._bindToParent != null || (this._parentField != null && this._idField != null));
    }

    updateNodeParent(pNode: NodeItem<T>, pParent?: NodeItem<T>) {
        if (pNode.isLoading) { return; }

        if (this._bindToParent != null) {
            this._bindToParent(pNode, pParent);
        } else if (this._parentField != null && this._idField != null) {
            const parentId = pParent?.dataItem?.[this._idField] ?? null;
            if (pNode.dataItem) {
                (pNode.dataItem as any)[this._parentField] = parentId;
            }
        }
        // pNode.dataItem?.reset();
    }

    findNodeById(pId: string | number) {
        const traverseFind = (nodeArray: NodeItem<T>[]): NodeItem<T> | null => {
            for (const node of nodeArray) {
                if (node.keyArray.at(-1) == pId) {
                    return node;
                }

                if (node.hasNodes) {
                    const foundNode: NodeItem<T> | null = traverseFind(node.details);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
            return null;
        };
        return traverseFind(this._dataObject.nodeData.root);
    }

    getRequiredFields() {
        let fields: string[] = [];
        const push = (pValue?: string) => { if (pValue) { fields.push(pValue); } }
        push(this._idField);
        push(this._parentField);
        push(this._idPathField);
        if (this._additionalFields) {
            fields = [...fields, ...this._additionalFields];
        }
        return fields;
    }

    // getPlaceholder: (pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) => NodeItem<T>;
    getNodes(_pData: T[], _pOptions: NodeDataStructureOptions) {
        throw new TypeError('loadFullStructure is not supported with hiearachy configurations at the moment');
        return {
            root: [],
            boundry: []
        };
    }

    getPlaceholder(pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) {
        if (pNode.getFilterString == null) { throw new Error(`Can't load placeholder with no filter string`); }
        const placeholderNode: NodeItem<T> = new NodeItem<T>({
            key: [...pNode.keyArray, '<PLACEHOLDER>'],
            getNodeData: () => this._dataObject.nodeData,
            getFilterString: pNode.getFilterString,
            beforeLoad: (pNode) => this._replacePlaceholder(pNode, pOptions),
            getConfiguration: () => this,
            passthroughKeys: []
        });
        placeholderNode.level = pNode.level + 1;
        return placeholderNode;
    }

    private async _replacePlaceholder(pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) {
        const parentNode = pNode.getParent();
        if (parentNode == null) { throw new Error(`Placeholder item does not have a parent`); }
        const nodes = await this.getStructure(pOptions, parentNode);
        parentNode.details.splice(0, parentNode.details.length);
        nodes.forEach(node => {
            node.getParent = () => parentNode;
            parentNode.details.push(node);
        });
        this._dataObject.nodeData.update();
    }
}

export function ExpandableCellRenderer<T extends ItemModel = ItemModel>(props: {
    row: NodeItem<T>;
    column: DataColumn;
}) {
    function handleOnClick(e: MouseEvent) {
        if (props.row.isLoading) { return; }
        if ((props.column.cellRendererParams as any)?.handleClick) {
            e.preventDefault();
            e.stopPropagation();
            (props.column.cellRendererParams as any).handleClick(props.row, props.column);
        }
    }

    const marginLeft = (props.column.cellRendererParams as any).getLeftMargin(props.row, props.column);
    const expandable = (props.column.cellRendererParams as any).isExpandable(props.row, props.column);
    const isNodeItem = props.row instanceof NodeItem;
    let collapsed;
    if (expandable) {
        collapsed = (props.column.cellRendererParams as any).isCollapsed(props.row, props.column);
    }
    const display: string = (props.column.cellRendererParams as any).getDisplay(props.row, props.column);
    const loading: boolean = (props.column.cellRendererParams as any).isLoading(props.row, props.column);
    const loadingStyle = 'border-width: var(--bs-spinner-border-width); border-style: solid; border-color: currentColor; border-right-color: transparent;';

    const prefix = (props.column.cellRendererParams as any).hasPrefix
        ? (props.column.cellRendererParams as any).getPrefix(props.row)
        : null;

    const boldDisplay = expandable && ((props.column.cellRendererParams as any).boldDisplay ?? false);

    const canInsert = props.row.canCreateNodes;

    const rootStyle = `margin-left: ${marginLeft}px`;

    const addRowBelow = () => {
        window.setTimeout(() => {
            if (props.row.addSibling) {
                props.row.addSibling();
            }
        }, 10);
    };

    const DisplayTag = boldDisplay ? 'b' : 'span';
    return h('span', {
        style: rootStyle,
    },
        loading
            ? [
                h('span', {
                    class: 'spinner-border spinner-border-sm text-primary me-2',
                    role: 'status',
                    'aria-hidden': true,
                    style: loadingStyle
                }),
                'Loading...'
            ]
            : expandable
                ? [
                    h('span', {
                        class: 'o365-group-expand me-1 p-2',
                        role: 'button',
                        onClick: handleOnClick
                    },
                        h('i', { class: collapsed ? 'bi bi-plus-square' : 'bi bi-dash-square' })),
                    prefix
                        ? h('span', null, [
                            prefix,
                            h(DisplayTag, null, display)
                        ])
                        : h(DisplayTag, null, display),
                    canInsert
                        ? h('button', {
                            onClick: addRowBelow,
                            class: 'btn btn-sm btn-link float-end me-1 o365-group-expand-add-below d-none py-0',
                            title: 'Add new row below'
                        },
                            h('i', { class: 'bi bi-plus-circle' }))
                        : ''
                ]
                : [
                    h('span', {
                        class: 'o365-group-expand me-1 p-2', style: isNodeItem ? undefined : 'display: none;'
                    }, h('i', { class: 'bi bi-square', style: 'color: transparent;' })),
                    prefix
                        ? h('span', null, [
                            prefix,
                            h(DisplayTag, null, display)
                        ])
                        : h(DisplayTag, null, display),
                    canInsert
                        ? h('button', {
                            onClick: addRowBelow,
                            class: 'btn btn-sm btn-link float-end me-1 o365-group-expand-add-below d-none py-0',
                            title: 'Add new row below'
                        },
                            h('i', { class: 'bi bi-plus-circle' }))
                        : ''
                ]);
}
ExpandableCellRenderer.props = ['row', 'column'];

export type NodeDataGroupByConfigurationOptions<T extends ItemModel = ItemModel> = {
    type: 'groupBy',
    fieldName?: string;
    fields?: string[];
    aggregates?: NodeDataGroupByAggregate<T>[];
    pathField?: string;
    pathMode?: boolean;
    pathIdReplace?: (pPathId: string | number) => Promise<string>;
}

type NodeDataGroupByAggregate<T extends ItemModel = ItemModel> = {
    name: keyof T & string,
    aggregate: NonNullable<RecordSourceFieldType['aggregate']>
}

type NodeDataHierarchyConfigurationOptions<T extends ItemModel = ItemModel> = {
    type: 'hierarchy'
    idField?: string;
    parentField?: string;
    idPathField?: string;
    /**
     * When set to true nodes that have parents will reuqire the parent records to be present in the where clause. This will also 
     * move the context filter from where clause to filter string.
     * Otherwise nodes that are missing parents in the current where clause will be treated as root elements
     */
    requireParents?: boolean;
    /**
     * Additional fields to retreive when getting the structure
     */
    additionalFields?: string[];
    /**
     * Function used when adding new items in the node structure. Should update `pItem` with values from 
     * the `pParent`  
     * @param pItem Current node being updated
     * @param pParent Parent node of the pItem
     */
    bindToParent?: (pItem: NodeItem<T>, pParent?: NodeItem<T>) => void;
    /**
     * Optional function for generating a unique path array from retrieved structure configuration options
     * For example this is can be used to create folder/hierarchy structures based on a single string field
     */
    parseKeyArray?: (pitem: Partial<T>) => string[]
    /**
     * Optional function for creating summary rows for nodes missing parent data. 
     * If this function is not provided nodes with missing parents in the structure will be excluded.
     */
    getSummaryItem?: (pDetails: NodeItem<T>[], pNode: NodeItem<T>) => T;
    /**
     * Optional function for aggreating summary rows that have only detail summary item.
     * For example instead of having single child rows like 'Summary Row 1' -> 'Summary Row 2' -> 'Data Row' you can combine them into
     * 'Summary Row 1/Summary Row 2' -> 'Data Row'
     */
    aggregateSummaryNodes?: (pDetails: NodeItem<T>[]) => string;
    /** Developer set condition if a node can instert new nodes */
    canCreateNodes?: (pNode: NodeItem<T>) => boolean;
}

/** Options for constructing group and tree structures  */
type NodeDataStructureOptions = {
    /** Map of keys that should be expanded by default */
    expandedKeys?: Record<string, boolean>
    /** Auto expand nodes when filtering */
    autoExpandOnFilter?: boolean;
    /** Used to determine the staring level */
    startingLevel?: number;
}

type NewNodeOptions<T extends ItemModel = ItemModel> = {
    item?: T | DataItemModel<T>
}

type NodeDataEvents<T extends ItemModel = ItemModel> = {
    'NodeAdded': (node: NodeItem<T>) => void,
    'ExpandedToNode': (node: NodeItem<T>) => void,
    'ConfigurationAdded': (pConfig: INodeDataLevelConfiguration<T>) => void,
    'SetGroupBy': () => void,
};

type Field<T> = keyof T & string;

class PathNode<T> {
    children = new Map<T, PathNode<T>>();
    value?: T

    get childrenArray() { return Array.from(this.children.values()) }

    constructor(pValue: T) {
        this.value = pValue;
    }
}

/** Sort data on the client side */
function _sortData(pOptions: RecordSourceOptions, pData: any[], fields: any) {
    const sortOrder = pOptions.fields?.filter(x => x.sortOrder != null);

    if (sortOrder && sortOrder.length > 0) {
        sortOrder.sort((a, b) => a.sortOrder! - b.sortOrder!);
        sortOrder.forEach(item => {
            const field = fields[item.name];
            const fieldType = field?.type ?? 'string';
            pData.sort(_sortByKey(item.name, item.sortDirection, fieldType));
        });
    }

    return pData;
}

/** Get a sort function for a specific item key */
function _sortByKey(pKey: string, pOrder: 'asc' | 'desc' | null | undefined, pType: any) {
    return (_a: any, _b: any) => {
        let a = _a[pKey];
        let b = _b[pKey];
        if (pKey === 'string') {
            if (a && b) {
                if (a !== null) { a = a.toString().toUpperCase(); }
                if (b !== null) { b = b.toString().toUpperCase(); }
            }
        } else if (['datetime', 'date'].includes(pType)) {
            a = new Date(a as string);
            b = new Date(b as string);
        }
        switch (pType) {
            case 'number':
                return pOrder === 'asc' ? (a - b) : (b - a);
            case 'string':
            case 'date':
            case 'datetime':
            case 'uniqueidentifier':
                return pOrder === 'asc' ? ((a < b) ? -1 : (b < a) ? 1 : 0) : ((a > b) ? -1 : (b > a) ? 1 : 0);
            default:
                return 0;
        }
    };
}

export { TreeColumnHeaderFactory };
